<template>
	<div class="cart" v-loading="loading">
		<template v-if="cartList.length || invalidGoods.length">
			<nav>
				<li>
					<el-checkbox v-model="checkAll" @change="allElection"></el-checkbox>
				</li>
				<li>{{ $lang('tmdDOTco.00427', $route) }}</li>
				<li>{{ $lang('tmdDOTco.00428', $route) }}</li>
				<li>{{ $lang('tmdDOTco.00429', $route) }}</li>
				<li>{{ $lang('tmdDOTco.00430', $route) }}</li>
				<li>{{ $lang('tmdDOTco.00431', $route) }}</li>
			</nav>
			<div class="list" v-for="(siteItem, siteIndex) in cartList" :key="siteIndex">
				<div class="item">
					<!-- <div class="head">
						<el-checkbox v-model="siteItem.checked" @change="siteAllElection(siteIndex)"></el-checkbox>
						<router-link to="/shop" target="_blank">{{ siteItem.siteName }}</router-link>
						<el-tag size="small" v-if="siteItem.cartList[0].is_own == 1">自营</el-tag>
					</div> -->
					<ul v-for="(item, cartIndex) in siteItem.cartList" :key="cartIndex">
						<li>
							<el-checkbox v-model="item.checked" @change="singleElection(siteIndex, cartIndex)"></el-checkbox>
						</li>
						<li class="goods-info-wrap" @click="$router.pushToTab({ path: '/sku-' + item.sku_id })">
							<div class="img-wrap"><img class="img-thumbnail" :src="$img(item.sku_image, { size: 'mid' })" @error="imageError(siteIndex, cartIndex)" /></div>
							<div class="info-wrap">
								<h5>{{ item.sku_name }}</h5>
								<template v-if="item.sku_spec_format">
									<span v-for="(x, i) in item.sku_spec_format" :key="i">
										{{ x.spec_name }}：{{ x.spec_value_name }} {{ i < item.sku_spec_format.length - 1 ? '；' : '' }}
									</span>
								</template>
							</div>
						</li>
						<li>
							<span>{{ $lang('components.currency_symbol_left', $route) + item.discount_price + $lang('components.currency_symbol_right', $route) }}</span>
						</li>
						<li>
							<el-input-number v-model="item.num" :step="modifyNum" size="mini" :min="1" :max="item.stock" @change="cartNumChange($event, { siteIndex, cartIndex })"></el-input-number>
						</li>
						<li>
							<strong class="subtotal ns-text-color" v-if="item.discount_price * item.num">{{ $lang('components.currency_symbol_left', $route) + $util.filterPrice(item.discount_price * item.num) + $lang('components.currency_symbol_right', $route) }}</strong>
							<strong class="subtotal ns-text-color" v-else>{{ $lang('components.currency_symbol_left', $route) + '0' + $lang('components.currency_symbol_right', $route) }}</strong>
						</li>
						<li>
							<el-button type="text" @click="deleteCart(siteIndex, cartIndex)">{{ $lang('tmdDOTco.00432', $route) }}</el-button>
						</li>
					</ul>
				</div>
			</div>

			<div class="lose-list" v-if="invalidGoods.length">
				<div class="head">
					{{ $lang('tmdDOTco.00433', $route) }}
					<span class="ns-text-color">{{ invalidGoods.length }}</span>
					{{ $lang('tmdDOTco.00434', $route) }}
				</div>
				<ul v-for="(goodsItem, goodsIndex) in invalidGoods" :key="goodsIndex">
					<li>
						<el-tag size="small" type="info">{{ $lang('tmdDOTco.00435', $route) }}</el-tag>
					</li>
					<li class="goods-info-wrap">
						<div class="img-wrap"><img class="img-thumbnail" :src="$img(goodsItem.sku_image, { size: 'mid' })" @error="imageErrorInvalid(goodsIndex)" /></div>
						<div class="info-wrap">
							<h5>{{ goodsItem.sku_name }}</h5>
							<template v-if="goodsItem.sku_spec_format">
								<span v-for="(x, i) in goodsItem.sku_spec_format" :key="i">
									{{ x.spec_name }}：{{ x.spec_value_name }}{{ i < goodsItem.sku_spec_format.length - 1 ? '；' : '' }}
								</span>
							</template>
						</div>
					</li>
					<li>
						<span>{{ $lang('components.currency_symbol_left', $route) + goodsItem.discount_price + $lang('components.currency_symbol_right', $route) }}</span>
					</li>
					<li>{{ goodsItem.num }}</li>
					<li>
						<strong class="subtotal">{{ $lang('components.currency_symbol_left', $route) + $util.filterPrice(goodsItem.discount_price * goodsItem.num) + $lang('components.currency_symbol_right', $route) }}</strong>
					</li>
				</ul>
			</div>

			<footer>
				<el-checkbox v-model="checkAll" @change="allElection">{{ $lang('tmdDOTco.00436', $route) }}</el-checkbox>
				<ul class="operation">
					<li>
						<el-button type="text" @click="deleteCartSelected">{{ $lang('tmdDOTco.00437', $route) }}</el-button>
					</li>
					<li>
						<el-button type="text" @click="clearInvalidGoods" v-if="invalidGoods.length!=0">{{ $lang('tmdDOTco.00438', $route) }}</el-button>
					</li>
				</ul>
				<div class="sum-wrap">
					<div class="selected-sum">
						<span>{{ $lang('tmdDOTco.00439', $route) }}</span>
						<em class="total-count">{{ totalCount }}</em>
						<span>{{ $lang('tmdDOTco.00440', $route) }}</span>
					</div>
					<div class="price-wrap">
						<span>{{ $lang('tmdDOTco.00441', $route) }}</span>
						<strong class="ns-text-color">{{ $lang('components.currency_symbol_left', $route) + totalPrice + $lang('components.currency_symbol_right', $route) }}</strong>
					</div>

					<el-button type="primary" v-if="totalCount != 0" @click="settlement">{{ $lang('tmdDOTco.00442', $route) }}</el-button>
					<el-button type="info" v-else disabled @click="settlement">{{ $lang('tmdDOTco.00443', $route) }}</el-button>
				</div>
			</footer>
		</template>
		<div class="empty-wrap" v-else-if="!loading && (!cartList.length || !invalidGoods.length)">
			<router-link to="/">{{ $lang('tmdDOTco.00444', $route) }}</router-link>
		</div>
	</div>
</template>

<script>
	import cart from './cart';
	export default {
		name: 'cart',
		mixins: [cart]
	};
</script>
<style lang="scss" scoped>
	@import './cart.scss';
</style>
